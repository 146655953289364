import React from 'react'
import { HashLink } from 'react-router-hash-link';



function DownloadPennytots() {
    return (
         <a href="#download-section">
    
            <span className='text-3 !bg-primary p-[15px] rounded-lg'>  Download Pennytots</span></a>

    )
}

export default DownloadPennytots;




