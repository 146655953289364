import React, { useCallback, useEffect, useState } from 'react'
import LogoSVG from 'src/assets/svg/logo.svg';
import { useReportAnIssue } from '../../redux/guests/hook';
import { useForm } from 'react-hook-form';
import DOMPurify from 'dompurify';
import FormInput from '../../components/form/text-input';
import Button from '../../components/form/button';
import FormTextArea from 'src/components/form/textarea';
import MainLayout from 'src/layout/main';
import ContactUs from 'src/assets/svg/contact-us.svg';
import Footer from 'src/components/footer';
import SelectForm from '../../components/form/select';
import {
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import FileInput from 'src/components/form/file-input';


function ReportAnIssuePage() {

    const { mutateAsync: report, isLoading } = useReportAnIssue();
    const [showDone, setShowDone] = useState(false);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [category, setCategory] = useState(null);


    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha('contactus');

        return token;

    }, [executeRecaptcha]);



    const {
        register,
        handleSubmit,
        formState: { isValid, errors },
    } = useForm({
        mode: 'onChange',
    });


    async function submitForm(data: any) {
        let name = DOMPurify.sanitize(data?.name);
        let email = DOMPurify.sanitize(data?.email);
        let content = DOMPurify.sanitize(data?.content);

        const token = await handleReCaptchaVerify();

        if (!category) {
            alert('Category is required');
            return
        }


        let payload = {
            email,
            name,
            content,
            category,
            'g-recaptcha-response': token
        }

        report(payload).then((result) => {
            setShowDone(true);
        })
    }


    useEffect(() => {
        document.title = "Pennytots |  Contact Us";
    }, []);



    const categories = [{
        label:'Account Management',

        value:"Account Management"
    },
    {
        label:'Likes and Comments ',

        value:"Likes and Comments "
    },
    {
        label:'Membership Options and Payment',

        value:"Membership Options and Payment"
    },
    {
        label:'Messages',

        value:"Messages"
    },
    {
        label:'Photo Management',

        value:"Photo Management"
    },
    {
        label:'Safety and Security',

        value:"Safety and Security"
    },
    {
        label:'General Inquiry',

        value:"General Inquiry"
    },
    {
        label:'Site Requirements and Technical Issues',

        value:"Site Requirements and Technical Issues"
    },
    {
        label:'Account Deletion',

        value:"Account Deletion"
    },
    {
        label:'Bug report',

        value:"Bug report"
    }]
    
    const categorie = [{
        label: 'IOS',
        value: "IOS"
    },
    {
        label: 'Android',
        value: "Android"
    },
    {
        label: 'Tablet',
        value: "Tablet"
    },
    {
        label: 'Others',
        value: "Others"
    }]



    return (

        <MainLayout>
            <div className='flex flex-col pt-10 px-4'>

                <div className='flex flex-row md:justify-center'>
                    <div className='w-full md:w-[500px]'>
                        {showDone ? (
                            <div className='mt-6'>
                                <span className='sub'>Success! Your report has been submitted</span>
                            </div>
                        ) : (
                            <div className='flex flex-col w-full md:w-[500px]'>
                                <div className='flex flex-col mb-3'>
                                    <span className='text-[22px] font-medium'> Contact us </span>
                                    <span className='text-[14px]'>Have any questions to ask us?</span>
                                    <span className='text-[13px]'>
  <em>
    *Users intending to delete their pennytots account or their account across any other app under Laten Geuberen are to ensure to select "Account deletion" and provide the necessary details. Account deletion will permanently remove all your information, including user data, posts, and media, from the platform
  </em>
</span>
                                    


                                </div>
                                <div className='flex flex-col'>

                                    <form onSubmit={handleSubmit(submitForm)}>

                                        <div className='flex flex-col justify-between'>
                                            <div className=' md:mr-3 mr-0'>
                                                <FormInput
                                                    label='Name *' 
                                                    type='text'
                                                    name='name'
                                                    validator='text'
                                                    register={register}
                                                    required={true}
                                                    readOnly={isLoading}
                                                    error={errors.name}
                                                    errorMessage={errors.name && errors.name.message}
                                                />
                                            </div>

                                            <div>
                                                <FormInput
                                                    label='Email'
                                                    type='email'
                                                    name='email'
                                                    validator='email'
                                                    register={register}
                                                    required={true}
                                                    readOnly={isLoading}
                                                    error={errors.email}
                                                    errorMessage={errors.email && errors.email.message}
                                                />
                                            </div>




                                        </div>



                                        <SelectForm
                                            label='Category'
                                            options={categories}
                                            customClass='my-4 !bg-red-500'
                                            onChange={(selected: any) => setCategory(selected)}
                                        />


                                        <FormTextArea
                                            label='Details'
                                            name='content'
                                            validator='text'
                                            register={register}
                                            required={true}
                                            readOnly={isLoading}
                                            error={errors.content}
                                            errorMessage={errors.content && errors.content.message}
                                        />


                                        <div>
                                            <SelectForm
                                                label='Device'
                                                options={categorie}
                                                customClass='my-4 !bg-red-500'
                                                onChange={(selected: any) => setCategory(selected)}


                                            />
                                        </div>


                                        <div>
                                            <FileInput label="Attachment" />
                                        </div>




                                        <Button
                                            disabled={!isValid}
                                            type='submit'
                                            loading={isLoading}
                                            color='primary'
                                            style={{
                                                height: 50
                                            }}
                                        >
                                            <span className='text-white'>Submit </span>
                                        </Button>
                                    </form>
                                </div>

                            </div>
                        )}
                    </div>


                    <div className='hidden md:flex pl-10'>
                        <img src={ContactUs} />
                    </div>
                </div>
                {/* Footer */}
                <Footer />

            </div>
            )
        </MainLayout>
    )
}

export default ReportAnIssuePage