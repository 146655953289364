export type FormValidationTypeProps = 'default' | 'email' | 'text' | 'password';

//   } else if (type === 'default') {
//     return {
//       required: required ? 'This field cannot be empty' : false,
//     };
//   } else if (type === 'number') {
//     return {
//       required: required ? 'This field is required' : false,
//       min: min
//         ? {
//             value: min,
//             message: `Minimum value is ${min}`,
//           }
//         : null,
//       max: max
//         ? {
//             value: max,
//             message: `Maximum value is ${max}`,
//           }
//         : null,
//     };
//   } else if (type === 'password') {
//     return {
//       required: required ? 'This field is required' : false,
//       minLength: {
//         value: 8,
//         message: 'Must be atleast 8 characters',
//       },
//     };
//   } else if (type === 'file') {
//     return {
//       required: required ? 'This field is required' : false,
//     };
//   } else if (type === 'select') {
//     return {
//       required: required ? 'This field is required' : false,
//     };
//   } else if (type === 'radio') {
//     return {
//       required: required ? 'This field is required' : false,
//     };
//   } else if (type === 'checkbox') {
//     return {
//       required: required ? 'This field is required' : false,
//     };
//   } else if (type === 'date') {
//     return {
//       required: required ? 'This field is required' : false,
//     };
//   }
// };

export const formValidator = {
  email: (required: boolean) => {
    return {
      required: required ? 'Email address is required' : false,
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Invalid email address',
      },
    };
  },
  text: (required: boolean) => {
    return {
      required: required ? 'This field is required' : false,
      minLength: {
        value: 1,
        message: 'Must be more than 1 character',
      },
    };
  },
  default: (required: boolean) => {
    return {
      required: required ? 'This field cannot be empty' : false,
    };
  },
  password: (required: boolean) => {
    return {
      required: required ? 'This field is required' : false,
      minLength: {
        value: 8,
        message: 'Must be at least 8 characters',
      },
    };
  },
};
