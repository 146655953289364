import classNames from 'classnames';
import React from 'react';
import Loader from 'src/assets/images/loading.gif';

interface FormButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    classNames?: string;
    color?: 'primary' | 'secondary';
    disabled?: boolean;
    loading?: boolean;
    children?: React.ReactNode;
}


const Button = ({
    className,
    color = 'primary',
    disabled,
    loading,
    children,
    ...rest
}: FormButtonProps) => {


    let btnClass =
        color === 'secondary'
            ? '!bg-secondary rounded-md h-[58px]'
            : classNames(' rounded-[5px] h-[65px] flex justify-center items-center', {
                '!bg-secondary': loading
            },
                { '!bg-primary': !loading }
            );

    return (
        <button
            className={`w-full  ${btnClass}  ${className}`}

            disabled={disabled || loading}
            {...rest}
        >
            {loading ? (
                <div className='flex justify-center'>
                    <img src={Loader} alt="" width="30px" />
                </div>

            ) : (<>
                {children}
            </>

            )}

        </button>
    );
};

export default Button;
