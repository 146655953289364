import { Axios } from './axios';

const resetAppPassword = async (payload: any) => {
  const { data } = await Axios.post('/user/verify-email-token', payload);
  return data;
};

const reportAnIssue = async (payload: any) => {
  const { data } = await Axios.post('/app/report-an-issue', payload);
  return data;
};

export const guests = {
  resetAppPassword,
  reportAnIssue
};
