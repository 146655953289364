import { guests } from 'src/api/guest';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ShowAlert } from 'src/providers/toast';

export function useResetAppPassword() {
  return useMutation(
    (payload: any) => {
      return guests.resetAppPassword(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        ShowAlert({
          type: 'success',
          message:
            'Password has been changed. Please login to your app with your new password',
        });
      },
    },
  );
}

export function useReportAnIssue() {
  return useMutation(
    (payload: any) => {
      return guests.reportAnIssue(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        ShowAlert({
          type: 'success',
          message: response.message,
        });
      },
    },
  );
}
