import React from 'react';
import { FormValidationTypeProps, formValidator } from '../../providers/formValidator';


interface FormInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    id?: string;
    name?: string;
    validator?: FormValidationTypeProps;
    label?: string;
    className?: string;
    value?: any;
    inputRef?: any;
    readOnly?: boolean;
    error?: any;
    errorMessage?: any;
    infos?: string;
    CustomLabel?: React.ReactNode;
    theme?: 'primary' | 'secondary';
    register?: any;
    required?: boolean;
}


const FormTextArea = ({
    id,
    label,
    name,
    className,
    value,
    inputRef,
    readOnly,
    error,
    errorMessage,
    infos,
    CustomLabel,
    theme,
    register,
    validator,
    required = false,
    ...rest
}: FormInputProps) => {


    return (
        <div className='pb-[20px] w-full'>
            <div className='flex flex-col'>
                <label className='title pb-2' htmlFor={id}>
                    {label}
                </label>
                <textarea
                    className={`min-h-[170px] p-4 w-full border border-gray-300 rounded-lg flex flex-col ${className}`}
                    defaultValue={value}
                    {...register(name, formValidator[validator!](required))}
                    {...rest}
                >

                </textarea>
                {CustomLabel}

            </div>
            {error && <span className='text-color-error text-[13px]'>* {errorMessage}</span>}
        </div>
    );
};

export default FormTextArea;
