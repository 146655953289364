import React, { useState } from 'react';

const FileInput = ({ label }: any) => {
    const [selectedFile, setSelectedFile] = useState<any>(null);

    const handleFileSelection = (event: any) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        // Logic to handle file selection
    };

    return (

        <div className="pb-4 flex flex-col">
            <label htmlFor="fileInput" className="title pb-2">
                {label}
            </label>

            <div className="w-full h-12 border border-gray-300 rounded-lg items-center justify-center cursor-pointer flex flex-col">
                {!selectedFile ? (
                    <span className="text-gray-400 font-medium">--Select or drop files here--</span>
                ) : (
                    <span className="text-gray-400">{selectedFile.name}</span>
                )}
                <input
                    id="fileInput"
                    type="file"
                    className="opacity-0  absolute"
                    onChange={handleFileSelection}
                    tabIndex={-1}
                    aria-hidden="true"
                />
            </div>
        </div>
    );
};

export default FileInput;