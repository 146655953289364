import React, { useEffect } from 'react'
import MainLayout from 'src/layout/main'
import LandingMobileSVG from 'src/assets/svg/landing-mobiles.svg';
import LandingMobileViewSVG from 'src/assets/svg/landing-mobile-view.svg';
import DownloadPennytots from 'src/components/download-pennytots';
import LogoSVG from 'src/assets/svg/logo.svg';
import HeroSVG from 'src/assets/svg/heroimg3.svg';
import AppleStoreSVG from 'src/assets/svg/apple-store.svg';
import PlayStoreSVG from 'src/assets/svg/play-store.svg';
import Section21SVG from 'src/assets/svg/section-2-1.svg';
import Section22SVG from 'src/assets/svg/section-2-2.svg';
import Section23SVG from 'src/assets/svg/section-2-3.svg';
import Footer from 'src/components/footer';
import { HashLink } from 'react-router-hash-link';
import { useNavigate } from 'react-router-dom';

function LandingPage() {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Pennytots | Home";
        window.scrollTo(0, 0);
    }, []);


    const section2 = [
        {
            title: "Cheaper",
            subtitle: 'We provide Android and iOS mobile apps at less than 70% of their average market costs',
            svg: Section21SVG
        },
        {
            title: "Better",
            subtitle: 'We ensure sustainability of your apps after they have been developed',
            svg: Section22SVG,
            // button: {
            //     title: "We ensure sustainability of your apps after they have been developed",
            //     action: () => {
            //         navigate('/our-process');
            //     }
            // }
        },
        {
            title: "Faster",
            subtitle: 'Our delivery timelines are sooner than most app delivery teams',
            svg: Section23SVG
        }
    ];

    return (
        <MainLayout>
            <div className='flex flex-col  mt-10 md:mt-10'>

                {/* Section 1 */}
                <div className='flex flex-col md:flex-row items-center justify-center h-screen'>
                    <div className='w-full md:w-1/2 flex flex-col px-3 justify-between'>
                        <div className='flex flex-col pl-0 md:pl-6'>
                            <span className='text-[30px] font-bold -[10px] md:leading-[60px] md:text-[60px] text-center md:!text-left  mb-3'>
                                Play, Learn & Win!
                            </span>
                            <div className='w-full text-center md:!text-left mt-3'>
                                <span className='text-[13px] md:text-[20px] leading-[19.5px] md:leading-[38px]'>

                                    Attention all trivia champions! The ultimate trivia challenge awaits. Download now and master world knowledge in a fun and easy way!  
                                    
                                {/* <div className='hidden md:flex mt-6 md:text-[20px]'>
  We ensure safe spaces for elite networking and fun, away from the typical dangers of mass online communities. Enjoy features such as filtered posts, search directories, professional profiles, account settings, document sharing capabilities, private chatting, group chatting, and more.
</div>*/}


                                    {/* <div className='pb-4 hidden md:block'>
                                        <span className='text-[20px] font-medium text-color-black leading-[50px]'> Try our prototype app, Pennytots </span>
                                    </div> */}
                                </span>
                            </div>


                            <div className='mt-10 hidden md:block'>
                                <DownloadPennytots />
                            </div>
                        </div>

                        {/* <hr className='divider hidden md:block' /> */}


                        <div className='pb-4 hidden md:block pl-0 md:pl-6 max-w-[550px] mt-[100px]'>
                       {/* </span className='text-[50px] font-bold -[10px] md:text-[56px] font-bold leading-[25px] font-medium text-color-black leading-[40px]'>
                                Network with People like You</span> */}

                            {/* <div className='w-full max-w-[500px] text-center md:!text-left mt-4'>
                                <span className='text-[13px] md:text-[18px] leading-[38px]'>
                                    We ensure safe spaces for elite networking and fun, away from the typical dangers of mass online communities. Enjoy features such as filtered posts, search directories, professional profiles, account settings, document sharing capabilities, private chatting, group chatting, and more.                                </span>                         </div> */}

                            {/* <div className='flex flex-col mt-3 w-full'>
                                <span className='subtitle pb-3'> View all features here: Pennytots</span>
                                <div>
                                    <a href="https://www.facebook.com/100064270322197/posts/pfbid0HCzruDtruJoNSxRwcafgCM4BiDrGpuUYU84QY6Su8YRy558yBDPzcm89qTKf2uVfl" target="_blank" className='!bg-primary p-[12px] px-[35px] rounded-lg mt-1' rel="noreferrer">
                                        <span className='text-[13px] md:text-[16px]'> Our Features </span>
                                    </a>
                                </div>

                            </div> */}

                        </div>
                    </div>

                    <div className='w-full md:w-1/2 flex justify-between'>
                        <div className='hidden md:block'>
                            <img src={HeroSVG} alt="Data illustrations by Storyset" />
                        </div>

                        <div className='flex md:hidden mt-5  justify-center w-full'>
                            <img src={HeroSVG} alt="" />
                        </div>

                    </div>

                    <div className='flex md:hidden flex-row justify-center mt-20'>
                     <DownloadPennytots />



                    </div>


                    {/* <div className='pb-4 flex flex-col md:hidden  pl-0 md:pl-6 mt-5'>
                        <span className='text-[18px] font-medium text-color-black leading-[50px] text-center'>
                            Get so many features in one place</span>

                        <div className='w-full text-center text-[13px] px-2 leading-6'>
                            Your app will have all necessary features including a search directory, professional profiles, account settings, document sharing capabilities, public posting, private chatting, group chatting, in-app payments, and so much more.
                        </div>

                        <div className='flex items-center justify-center w-full mt-2'>
                            <a href="https://facebook.com/latengeuberen" target="_blank" className='!bg-primary p-[12px] px-[35px] rounded-lg mt-2' rel="noreferrer">
                                <span className='text-[13px] md:text-[15px]'> Our Features </span>
                            </a>
                        </div>


                    </div> */}
                </div>

                {/* <div className='my-[50px] md:my-[100px] flex flex-col px-5 md:px-0'>
                    <div className='flex items-center flex-col'>
                        <div className='w-[50%]'>
                            <hr className='divider' />
                        </div>

                     <div className='mt-4 md:my-5 flex flex-row items-center'>
                            <span className='text-[20px] md:text-[40px] text-center'> We are cheaper, better, and faster</span>
                        </div> 

                        <div className='grid gap-4 grid-cols-1 md:grid-cols-3'>
                            {section2.map((item, index) => (
                                <div className='flex flex-col  items-center'>

                                <img src={item.svg} alt={index.toString()} width={'200px'} />

                                    <span className='mt-3 text-center text-[20px] font-medium'>{item.title}</span>
                                    
                                    <span className='mt-1 text-center text-[13px] font-normal'>{item.subtitle}</span>
                                    
                                    {item.button && <button onClick={item.button.action} className='!bg-primary p-[12px] rounded-lg mt-2'>
                                        <span className='text-[13px] md:text-[15px]'>  {item.button.title}</span>
                                    </button>}
                                </div>
                            ))}
                        </div>

                    </div>

                </div> */}


                {/* Section 2 */}

                <div id= "download-section" className='md:my-[100px] flex flex-col px-5 md:px-0'>
                    <div className='flex items-center flex-col'>
                        {/* <div className='w-[50%]'>
                            <hr className='divider' />
                        </div> */}

                        <div className='mt-5 hidden md:flex flex-row items-center'>
                            <span className='header-2 !font-normal'>
                                Download the
                            </span>
                            <div className='flex flex-col items-center mt-[11px] ml-4'>
                                <img src={LogoSVG} alt="" />
                            </div>

                            <span className='header-2 !font-normal ml-2'>
                                app
                            </span>
                        </div>

                        <div className='md:w-[500px] text-center mt-5 md:mt-0'>
                            <span className='text-[13px] font-normal md:header-3'>Learn something new with every question. Download today!</span>
                        </div>

                        <div className='flex flex-row mt-5' id="download">
                            <div>
                            <a href="https://apps.apple.com/ng/app/pennytots/id6502868947" target="_blank" rel="noopener noreferrer">
                                
                                <img src={AppleStoreSVG} alt="" />
                            </a>
                            </div>
                            <div className='ml-3'>
                            <a href="https://play.google.com/store/apps/details?id=com.pennytots.app" target="_blank" rel="noopener noreferrer">

                                <img src={PlayStoreSVG} alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>

        </MainLayout>

    )
}

export default LandingPage;
