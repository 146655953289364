import React, { useEffect } from 'react'
import MainLayout from 'src/layout/main'

import Footer from 'src/components/footer';
// import OurProcessSVG from 'src/assets/svg/our-process.svg';

export default function OurProcessPage() {

    useEffect(() => {
        document.title = "Pennytots | Our Process";
        window.scrollTo(0, 0);
    }, []);


    const appDevItems = [
        " App Design",
        "Android Build",
        " iOS Build",
        "Control Console Build",
        "Personnel Training & App Testing",
        " Setup & Hosting",
        " Legal services – User & Privacy Terms",
        " Website Development"
    ]


    return (
        <MainLayout>
            <div className='flex flex-col w-full'>
                <div className='flex items-center justify-center w-full mt-2'>
                    <span className='px-4 text-[20px] md:text-[30px] font-medium text-center'>The Pennytots App Development Process</span>
                </div>


                <div className="flex flex-col mt-3 md-1 mx-[5%] text-justify">
                    <div className="flex flex-col w-full">
                        <span className='text-[16px] md:text-[24px] mt-[4px] font-semibold'>1. Setting Up</span>
                       <ul className=' pl-[26px] md:pl-[40px] mt-[8px]'>
                        <span className='text-[13px] md:text-[16px]'><span className='font-semibold'><li> Founding Members: </li> </span>
                        We identify between ten to twenty Founding Members committed to funding the creation of the necessary mobile apps and its management for at least one year through contributions. Founding Members will forever be identified on the app for posterity and may nominate Board of Trustee Members (where a separate non-profit needs to be incorporated). Each Founding Member will be required to commit US$1,000 (in foreign or local currency) to the founding of dedicated digital platforms.</span>
                     </ul>
                    </div>

                    {/* App Development Section */}
                    <div className='flex flex-row mt-[8px] gap-4'>
                        <div className='w-full  flex flex-col'>
<ul className=' pl-[26px] md:pl-[40px]'>
                            <span className='text-[13px] md:text-[16px] mt-[8px]'><span className='font-semibold'><li> Active Members: </li> </span>
                            We identify and work with members who are committed to growing the vision of the digital platforms. These members will provide their experience, expertise, and contacts in assisting with incorporation, bank account setup, mobile app design, spreading the word, and advisory, e.t.c. Active members will be specially recognized on the app while they are active and may sit in functional committees.
                            </span>
</ul>
                            <div className='flex flex-row mt-[8px] gap-4'>
                        <div className='w-full  flex flex-col'>

<ul className=' pl-[26px] md:pl-[40px]'>
                            <span className='text-[13px] md:text-[16px] mt-[8px]'><span className='font-semibold'><li> Governance Structure: </li> </span>
                             We provide governance templates for consideration, including frequency of meetings, functional committees, bank account management, etc, taking into consideration how busy everyone is..
                            </span>
                            </ul>

                            <div className='flex flex-col w-full mt-3 gap-'>
                                <span className='text-[16px] md:text-[24px] mt-[4px] font-semibold'>2. App Development</span>

                                <span className='text-[13px] md:text-[16px] mt-[8px]'>
                                    We will proceed with developing  the mobile apps according to contributions provided by Founding Members and according to the following steps
                                </span>

                                <ul className='grid grid-cols-1 md:pl-[40px] pl-[26px] mt-1'>
                                    {appDevItems.map((item: string, index) => (
                                        <li className='text-[13px] md:text-[16px]'>
                                            {item}
                                        </li>
                                    ))}

                                </ul>

                                <span className='text-[13px] md:text-[16px]'>Payment plans will be shared as requested</span>

                            </div>
                        </div>
</div>
</div>                        {/* <div className='hidden md:flex w-1/2 pt-5'>
                            <img src={OurProcessSVG} alt="our process" className="w-full h-auto" />

                        </div> */}
                    </div>

                    <div className="flex flex-col w-full mt-3 gap-">
                        <span className='text-[16px] md:text-[24px] mt-[4px] font-semibold '>3. Sustainability</span>

                        <span className='text-[13px] md:text-[16px] mt-[8px]'>
                            We will assist with managing the developed app by working ONLY with alumni or association members to execute the following
                        </span>
                        <ul className=' pl-[26px] md:pl-[40px] mt-[10px]'>
                        <span className='text-[13px] md:text-[16px] mt-[16px] '><span className='font-semibold'><li> Fixes & Upgrades: </li></span>
                            This will include troubleshooting, app crash & bug fixing, app upgrades,
                            implementation of new features, development of new app versions for specific devices,
                            advisory on server, hosting, database management, etc.
                        </span>
                        </ul>
                        <ul className=' pl-[26px] md:pl-[40px] mt-[10px]'>
                        <span className='text-[13px] md:text-[16px] mt-[16px]'><span className='font-semibold'><li> Management: </li></span>
                        We will assist with hiring the required personnel and provide oversight on
marketing, business development, app user management, graphic design management,
advert postings, etc.
                        </span>
                        </ul>
                        <ul className=' pl-[26px] md:pl-[40px] mt-[10px]'>

                        <span className='text-[13px] md:text-[16px] mt-[16px]'><span className='font-semibold'><li> Fundraising: </li></span>
                            We will provide advisory services on how best to raise funds through membership
                            donations (in-app payments), advertisement revenue, sponsorship, etc.
                        </span>
                        </ul>
                        <ul className=' pl-[26px] md:pl-[40px] mt-[10px]'>

                        <span className='text-[13px] md:text-[16px] mt-[8x]'><span className='font-semibold'><li> Inclusion: </li> </span>
                            We will assist with managing the developed app by working ONLY with alumni or association members to execute the following
                            We will provide advisory on how to include stakeholders such as faculty, students,
                            advisors, consultants, etc.

                        </span>
                        </ul>

                    </div>


                </div>




                {/* Footer */}
                <Footer />

            </div>


        </MainLayout>
    )
}
