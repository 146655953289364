import React, { useState } from 'react';
import { Link, NavLink as ReactNavLink } from 'react-router-dom';
import LogoSVG from 'src/assets/svg/logo.svg';
import WorldSVG from 'src/assets/svg/world.svg';
import { HashLink } from 'react-router-hash-link';
import MobileMenu from 'src/components/mobile-menu';

interface AuthLayoutProps {
  children: React.ReactNode;
}

function MainLayout({ children }: AuthLayoutProps) {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const nav = [
    {
      title: "Home",
      route: '/',
    },
    // {
    //   title: "Download",
    //   route: '#download',
    //   hashLink: true
    // },
    // {
    //   title: "Our Process",
    //   route: '/our-process',
    // },
   
    {
      title: "Our Team",
      route: '/our-team',
      hashLink: false
    },

    {
      title: "Contact Us",
      route: '/contact-us',
    },
    {
      title: "Privacy Policy",
      route: '/privacy-Policy',
    },
    // {
    //   title: "resources",
    //   route: '/resources',
    // },

  ]



  return (
    <React.Fragment>
      <div className='flex flex-col'>

        {/* Desktop */}
        <div className='hidden md:flex flex-row justify-between p-4'>
          <div>
            <Link to="/">
              <img src={LogoSVG} alt="" className='w-[100px]' />
            </Link>

          </div>


          <div className='flex flex-row' >

            {nav.map((item, index) => (
              <ReactNavLink
                key={index}
                end
                to={item.route}
                className={({ isActive }) =>
                  isActive && !item.hashLink ? 'flex flex-row text-color-black h-[25px] items-center border-b-[3px] border-b-primary pb-2 px-4'
                    :
                    'flex flex-row text-color-black h-[25px] items-center px-4'
                }
              >

                {item.hashLink ? (
                  <HashLink to={item.route}>
                    <span className='text-inherit'> {item.title}</span></HashLink>
                ) :
                  <span className='text-inherit'> {item.title}</span>}

              </ReactNavLink>
            ))}

          </div>

        </div>

        <div className='flex md:hidden flex-row justify-between p-4 w-full'>
          <div>
            <img src={WorldSVG} alt="" />
          </div>
          <div>
            <Link to="/">
              <img src={LogoSVG} alt="" className='w-[100px]' />
            </Link>
          </div>
          <div className='flex flex-col'>

            <MobileMenu menu={nav} />
          </div>
        </div>

        <div>
          {children}
        </div>
      </div>


    </React.Fragment>

  )
}

export default MainLayout;