import React, { useState } from 'react'
import { useParams } from 'react-router';
import LogoSVG from 'src/assets/svg/logo.svg';
import { useResetAppPassword } from '../../redux/guests/hook';
import { useForm } from 'react-hook-form';
import DOMPurify from 'dompurify';
import FormInput from '../../components/form/text-input';
import Button from '../../components/form/button';
import { ShowAlert } from '../../providers/toast';


function ResetAppPasswordPage() {
    const { path } = useParams();

    const { mutateAsync: reset, isLoading } = useResetAppPassword();
    const [showDone, setShowDone] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { isValid, errors },
    } = useForm({
        mode: 'onChange',
    });


    async function submitForm(data: any) {
        if (path) {

            let password = DOMPurify.sanitize(data?.password);
            let confirmPassword = DOMPurify.sanitize(data?.confirm_password);

            if (password !== confirmPassword) {
                ShowAlert({ type: 'error', message: "Password do not match" })
                return
            }

            const decodedToken = atob(path);

            const token = decodedToken?.substring(0, 4);
            const email = decodedToken?.substring(4);


            let payload = {
                email,
                password,
                token
            }

            reset(payload).then((result) => {
                setShowDone(true);
            })
        }


    }


    if (!path) {
        return null
    }

    return (
        <div className='flex flex-col md:items-center pt-10 px-4'>
            <div className='w-full justify-center flex flex-col items-center mb-4'>
                <img src={LogoSVG} alt="" className='w-[100px] md:w-[200px]' />
                {!showDone && <span className='subtitle text-center'>Enter your new password</span>}

            </div>

            {showDone ? (
                <div className='mt-6'>
                    <span className='sub'>Success! Your password has been changed</span>
                </div>
            ) : (
                <div className='flex flex-col w-full md:w-[500px]'>
                    <div className='flex flex-col'>


                        <form onSubmit={handleSubmit(submitForm)}>
                            <FormInput
                                label='New Password'
                                type='password'
                                name='password'
                                validator='text'
                                register={register}
                                required={true}
                                readOnly={isLoading}
                                error={errors.password}
                                errorMessage={errors.password && errors.password.message}
                            />


                            <FormInput
                                label='Confirm Password'
                                type='password'
                                name='confirm_password'
                                validator='text'
                                register={register}
                                required={true}
                                readOnly={isLoading}
                                error={errors.password}
                                errorMessage={errors.confirm_password && errors.confirm_password.message}
                            />

                            <Button
                                disabled={!isValid}
                                type='submit'
                                loading={isLoading}
                                color='secondary'
                            >
                                <span className='text-white'>Reset Password</span>
                            </Button>
                        </form>
                    </div>

                </div>
            )}




        </div>
    )
}

export default ResetAppPasswordPage;