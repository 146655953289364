import React, { useEffect } from 'react'
import Footer from "src/components/footer";
import MainLayout from "src/layout/main";


export default function PrivacyAndPolicyPage() {


        useEffect(() => {
            document.title = "Pennytots | Privacy And Policy";
            window.scrollTo(0, 0);
        }, []);
    return (
        
        <MainLayout>

            <div className='flex flex-col w-full'>
                <div className='flex items-center justify-center w-full mt-2'>
                    <span className='px-4 text-[20px] md:text-[30px] font-medium text-center'>The Pennytots Privacy Policy</span>
                </div>
                <div className='flex flex-col mt-3 md-1 mx-[5%] text-justify'>
                <span className='text-[13px] md:text-[16px] mt-[4px]'>
                Pennytots has created this privacy policy in order to demonstrate our firm commitment to privacy. The following discloses the information gathering and dissemination practices for the Pennytots site. <br />
By using the Pennytots site and accepting the User Agreement you also agree to this Privacy Policy. If you do not agree to this Privacy Policy, you must not use the Pennytots site. The terms "We", "Us", "Our", or "Pennytots" includes Laten Geuberen Enterprises and our affiliates.  
</span>
                                    

</div>
                                </div>


                <div className="flex flex-col mt-3 md-1 mx-[5%] text-justify">
                    <div className="flex flex-col w-full">
                        <span className='text-[16px] md:text-[24px] mt-[4px] font-semibold'>What Information we Collect</span>
                        <div className="flex flex-col w-full">
                            <span className='text-[13px] md:text-[16px]'>
                          1.  Pennytots collects your information when you register on the Pennytots site and when you visit Pennytots pages. <br />
                          2.  Personal Information: We may collect the following types of personal information in order to provide you with the use and access to the Pennytots site, services and tools, and for any additional purposes set out in this Privacy Policy:               </span>      
                        <ul className=' pl-[26px] md:pl-[40px] mt-[8px]'>
                            <span className='text-[13px] md:text-[16px]'><span className=''><li> Name, and contact information, such as email address, phone number, mobile telephone number, physical address, and (depending on the service used) sometimes financial information, such as bank account numbers; </li> </span>
                            <span className='text-[13px] md:text-[16px]'><span className=''><li> Transactional information based on your activities on the sites (such as advertising, viewing adverts, and content you generate or that relates to your account), billing and other information you provide; </li> </span>
                            <span className='text-[13px] md:text-[16px]'><span className=''><li> Personal information you provide to us through correspondence, chats, dispute resolution, or shared by you from other social applications, services or websites; </li> </span>
                            <span className='text-[13px] md:text-[16px]'><span className=''><li>  Additional personal information we ask you to submit to authenticate yourself if we believe you are violating site policies (for example, we may ask you to send us an ID to answer additional questions online to help verify your identity); </li> </span>
                            <span className='text-[13px] md:text-[16px]'><span className=''><li>  Information from your interaction with our sites, services, content and advertising, including, but not limited to, device ID, device type, location, geo-location information, computer and connection information, statistics on page views, traffic to and from the sites, ad data, IP address and standard web log information. </li> </span>
                            </span>
                            </span>
                            </span>
                            </span>
                            </span>
                            
                        
                        </ul>
                        </div>
                    </div>
                    <div className="flex flex-col w-full">
                    <span className='text-[13px] md:text-[16px]'>
                           3. Aggregate Information: Pennytots collects non-identifying, general, generic and aggregate information to better design our Web site and services. <br/>
                           4. Non-Personal Information: Pennytots may collect non-personal information as you use Pennytots. When you use the site, Pennytots, third-party service providers (e.g. Google Analytics), and partners may receive and record non-personal information from cookies, server logs, and similar technology from your browser or mobile device, including your IP address. <br/>
                           5. We may combine some Non-Personal Information with the Personal Information we collect. Where we do so, we will treat the combined information as Personal Information if the resulting combination may be used to readily identify or locate you in the same manner as Personal Information alone.
                            </span>
                            </div>


                               <div className="flex flex-col w-full mt-3 gap-">
                        <span className='text-[16px] md:text-[24px] mt-[4px] font-semibold '>How Do We Store your Information</span>
                        <div className="flex flex-col w-full">
                    <span className='text-[13px] md:text-[16px]'>
                    Your information may be transferred to and stored on servers in various locations both in and outside of the United States. Pennytots maintains control of your information. We protect your information using technical and administrative security measures to reduce the risks of loss, misuse, unauthorised access, disclosure and alteration. Some of the safeguards we use are firewalls and data encryption, physical access controls to our data centres, and information access authorisation controls </span>
                                    <div className='flex flex-col w-full mt-3 gap-'>
                                        <span className='text-[16px] md:text-[24px] mt-[4px] font-semibold'> How we use your Information</span>
                                        

                                
                                        <div className="flex flex-col w-full">
                    <span className='text-[13px] md:text-[16px]'> 1.   When you use the Pennytots site, we may request certain information. Pennytots does not share any of your personally identifiable or transactional information with any person or entity, other than as set out in this policy. No other third party receives your personally identifiable information or other transactional data except for approximate location / geo-location information used by advertisers. The information we collect is used to improve the content of our web site, used to notify users about updates to our web site and for communications, such as customer service.
  
                                </span> </div> 
                                        
                                        
                                <div className="flex flex-col w-full">
                    <span className='text-[13px] md:text-[16px]'> 2.   Email Communications: We may send you a welcome email to verify your account and other transactional emails for operational purposes, such as billing, account management, or system maintenance. You may only stop those emails by terminating your account. We may also send you promotions, product announcements, surveys, newsletters, developer updates, product evaluations, and event information or other marketing or commercial e-mails. You can opt out of receiving these email communications from Pennytots at any time by unsubscribing using the unsubscribe link within each email, updating your e-mail preferences on your Pennytots account or emailing us to have your contact information removed from our email list or registration database.
  
                                </span>  </div>
                                        

                                <div className="flex flex-col w-full">
                    <span className='text-[13px] md:text-[16px]'>3. SMS communications: We may send you SMS messages to your secure phone number for operational purposes, such as notifying you about the status of your project. You can opt out of receiving these SMS messages from Pennytots at any time by updating your notifications preferences on your Pennytots account or by replying 'STOP' to any message we send.
  
                                 </span> </div>
                                       
                                 <div className="flex flex-col w-full">
                    <span className='text-[13px] md:text-[16px]'> 4. Marketing: You agree that we may use your personal information to tell you about our services and tools, deliver targeted marketing and promotional offers based on your communication preferences, and customise measure and improve our advertising. You can unsubscribe from emails at any time by clicking the unsubscribe link contained in the email.
  
                                 </span> </div>
                                    
                                 <div className="flex flex-col w-full">
                    <span className='text-[13px] md:text-[16px]'> 5. We do not rent, sell, or share Personal Information about you with other people or non- affiliated companies for marketing purposes (including direct marketing purposes) without your permission. We may use and share Non-Personal Information for our marketing purposes, including, without limitation, marketing on other websites. For example, we may use the information to control the number of times you have seen an ad, deliver ads tailored to your interests, and measure the effectiveness of ad campaigns. You can prevent us from tailoring our ads to you on other websites by deleting your cookies (see below).
  
                                </span> </div>

                                        <div className='flex flex-col w-full mt-3 gap-'>
                                        <span className='text-[16px] md:text-[24px] mt-[4px] font-semibold'> Sharing your Information</span>

                                        <div className="flex flex-col w-full">
                    <span className='text-[13px] md:text-[16px]'> 1. We disclose personal information to respond to legal requirements, enforce our policies, respond to claims that a listing or other content violates the rights of others, or protect anyone's rights, property, or safety. Such information will be disclosed in accordance with applicable laws and regulations. As stated above, we do not disclose your personal information to third parties for their marketing purposes without your explicit consent.
                                
                                 </span> </div>
                    
                                 <div className="flex flex-col w-full">
                    <span className='text-[13px] md:text-[16px]'> 2. We may disclose personal information to our related bodies corporate and third party suppliers and users located overseas for some of the purposes listed above. We take reasonable steps to ensure that the overseas recipients of your personal information do not breach the privacy obligations relating to your personal information.
                                
                                 </span> </div>
                                 <div className="flex flex-col w-full">
                    <span className='text-[13px] md:text-[16px]'> 3. We may disclose your personal information upon a business transition; At the sale or transfer of Pennytots and/or all or part of its assets, your personal information may be among the items sold or transferred.
                                
                                 </span> </div>

                                      <div className="flex flex-col w-full">
                    <span className='text-[13px] md:text-[16px]'> 4. We may disclose your personal information to entities located in any country or other applicable jurisdiction
                                
                                 </span> </div>
                                 <div className="flex flex-col w-full">
                    <span className='text-[13px] md:text-[16px]'> 5.  We may share aggregate data with advertisers and other third parties. Pennytots uses industry-standard encryption technologies when transferring and receiving consumer and transaction data exchanged with our site.                                       
                                 </span> </div>

                                       <div className='flex flex-col w-full mt-3 gap-'>
                                        <span className='text-[16px] md:text-[24px] mt-[4px] font-semibold'> Cookies</span>
                                        <div className="flex flex-col w-full">
                    <span className='text-[13px] md:text-[16px]'>  1.  Cookies are pieces of data assigned by a web server that uniquely identify the browser on your PC. Pennytots uses cookies called "persistent" cookies to enable the site to remember you on subsequent visits, speeding up or enhancing your experience of services or functions offered. Cookies also enable our systems to gather information about your navigational patterns through the site. You have the option to disable cookies at any time through your browsers. We may also store your website activity in cookies which may be used by third party vendors, including Google, to serve ads based on your behaviour on our website.
                                      </span> </div>
                                     <div className="flex flex-col w-full">
                    <span className='text-[13px] md:text-[16px]'>  2. The third party vendors (e.g. Google Analytics) may receive and record non-personal information from cookies, server logs, and similar technology from your browser or mobile device, including your IP address. You may opt out of Google's use of cookies by visiting the Google advertising opt-out page
                                       </span> </div>
                                      <div className="flex flex-col w-full">
                    <span className='text-[13px] md:text-[16px]'> 3. If you access our Services with your login credentials from a social networking site (e.g., Facebook or Twitter) or if you otherwise agree to associate your Pennytots account with a social networking account, we may receive personal information about you from such social networking site, in accordance with the terms of use and privacy policy of the social networking site. For example, Facebook may share with us your friend list, birthday, information about the interests of you or your friends or other personal information, in order to help us establish your account, tailor services to you and find other current or potential site users that you know. We may add this information to the information we have already collected from you via other aspects of the Site. You are also subject to the social networking site's terms of use and privacy policy. We use the OAuth (open authorisation) protocol to enable us to access this information without collecting your password when you agree to allow another application to access your account information.                                        
                                     </span>
                                     </div>


    
                                
                                    </div>
                                    </div>
                                    </div>
                                    </div>

                                    <div className='flex flex-col w-full mt-3 gap-'>
                                        <span className='text-[16px] md:text-[24px] mt-[4px] font-semibold'>Accessing and Updating your Information</span>
                                        
                                        <div className="flex flex-col w-full">
                    <span className='text-[13px] md:text-[16px]'> You can update your information through your account profile settings. If you believe that personal information we hold about you is incorrect, incomplete or inaccurate, then you may request us to amend it.
                                           Additionally, you may request access to any personal information we hold about you at any time by contacting us at Privacy@Pennytots.com. Where we hold information that you are entitled to access, we will try to provide you with suitable means of accessing it (for example, by mailing or emailing it to you). We may charge you a fee to cover our administrative and other reasonable costs in providing the information to you. We will not charge for simply making the request and will not charge for making any corrections to your personal information.
                                          There may be instances where we cannot grant you access to the personal information we hold. For example, we may need to refuse access if granting access would interfere with the privacy of others or if it would result in a breach of confidentiality. If that happens, we will give you written reasons for any refusal.                                       
                           </span> </div>
                             
                             
                            

                            </div>

                            <div className='flex flex-col w-full mt-3 gap-'>
                                        <span className='text-[16px] md:text-[24px] mt-[4px] font-semibold'>Changes to our Privacy Policy</span>
                                         
                                         <div className="flex flex-col w-full">
                    <span className='text-[13px] md:text-[16px]'> We may change this privacy policy from time to time. Any updated versions of this privacy policy will be posted on our website. Please review it regularly.
                                                                 This privacy policy was last updated on 25th March 2023. </span> </div>
                                                                
                                                                 
                                                                 </div>

                                {/* </div>
                            </div>
                        </div>                        {/* <div className='hidden md:flex w-1/2 pt-5'> */}
                        {/* <img src={OurProcessSVG} alt="our process" className="w-full h-auto" /> */}

                    </div> 
                    </div>
                

                




                {/* Footer */}
                <Footer />

            {/* </div> */}


        </MainLayout>
    );

}




