import React from 'react'
import Footer from 'src/components/footer';
import MainLayout from 'src/layout/main';


function ResourcesPage() {
    return (
        <MainLayout>
            <div className='flex flex-col'>
                <div className='mt-20 flex flex-col items-center'>
                    <span className='header-1'>  Resources</span>

                    <div className='w-[60%] mt-3'>
                        <input type="text" name="search" id="" className='search-input' />

                    </div>
                    <div className="flex flex-col my-8 px-10 w-full">
                        <div className=' flex flex-row items-center justify-between w-full mt-20'>
                            <div className='w-full'>
                                <hr />

                            </div>
                            <div className='w-full text-center'>
                                <span className='header-3'>Categories</span>
                            </div>
                            <div className='w-full'>
                                <hr />
                            </div>
                        </div>

                        <div className='flex flex-row w-full justify-evenly my-10'>
                            {['Video', 'Articles', 'Academic Papers', 'Journal Entries'].map((item, index) => (
                                <div className='p-4 px-6 border-2 !border-primary rounded-lg' key={index}>
                                    <span>{item}</span>
                                </div>
                            ))}
                        </div>


                        <div className=' flex flex-row items-center justify-between w-full mt-20'>
                            <div className='w-[10%]'>
                                <span className='header-3'>Articles</span>

                            </div>
                            <div className='text-center w-[60%]'>
                                <hr />

                            </div>
                            <div className='w-[20%]'>
                                <span className='header-3'>More</span>
                            </div>
                        </div>

                        <div className='flex flex-col my-10'>
                            <div className='flex flex-row justify-between'>

                                {/* Box */}
                                <div className='flex flex-row'>
                                    <div className='h-[160px] w-[250px] bg-color-gray-4 rounded-lg'>
                                    </div>
                                    <div className='flex flex-col justify-center ml-4 '>
                                        <span className='my-2 title-2-regular'> Cras euismod </span>
                                        <div className='flex w-full max-w-[250px]'>
                                            <span className='border-b-2 !border-b-primary pb-3 subtitle'>
                                                Cras euismod porta fringilla. Vestibulum vitae
                                            </span>
                                        </div>
                                    </div>

                                </div>
                                <div className='flex flex-row'>
                                    <div className='h-[160px] w-[250px] bg-color-gray-4 rounded-lg'>
                                    </div>
                                    <div className='flex flex-col justify-center ml-4 '>
                                        <span className='my-2 title-2-regular'> Cras euismod </span>
                                        <div className='flex w-full max-w-[250px]'>
                                            <span className='border-b-2 !border-b-primary pb-3 subtitle'>
                                                Cras euismod porta fringilla. Vestibulum vitae
                                            </span>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className='flex flex-row justify-between mt-5'>

                                {/* Box */}
                                <div className='flex flex-row'>
                                    <div className='h-[160px] w-[250px] bg-color-gray-4 rounded-lg'>
                                    </div>
                                    <div className='flex flex-col justify-center ml-4 '>
                                        <span className='my-2 title-2-regular'> Cras euismod </span>
                                        <div className='flex w-full max-w-[250px]'>
                                            <span className='border-b-2 !border-b-primary pb-3 subtitle'>
                                                Cras euismod porta fringilla. Vestibulum vitae
                                            </span>
                                        </div>
                                    </div>

                                </div>
                                <div className='flex flex-row'>
                                    <div className='h-[160px] w-[250px] bg-color-gray-4 rounded-lg'>
                                    </div>
                                    <div className='flex flex-col justify-center ml-4 '>
                                        <span className='my-2 title-2-regular'> Cras euismod </span>
                                        <div className='flex w-full max-w-[250px]'>
                                            <span className='border-b-2 !border-b-primary pb-3 subtitle'>
                                                Cras euismod porta fringilla. Vestibulum vitae
                                            </span>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>


                        <div className=' flex flex-row items-center justify-between w-full mt-20'>
                            <div className='w-[10%]'>
                                <span className='header-3'>Videos</span>

                            </div>
                            <div className='text-center w-[60%]'>
                                <hr />

                            </div>
                            <div className='w-[20%]'>
                                <span className='header-3'>More</span>
                            </div>
                        </div>


                        <div className='flex flex-col my-10'>

                            <div className='flex flex-row justify-between mt-5'>

                                {/* Box */}
                                <div className='flex flex-col'>
                                    <div className='h-[160px] w-[250px] bg-color-gray-4 rounded-lg'>
                                    </div>
                                    <div className='flex flex-col justify-center ml-4 '>
                                        <span className='my-2 title-2-regular'> Cras euismod </span>
                                        <div className='flex w-full max-w-[250px]'>
                                            <span className='border-b-2 !border-b-primary pb-3 subtitle'>
                                                LOTUS euismod porta fringilla. Vestibulum vitae
                                            </span>
                                        </div>
                                    </div>

                                </div>
                                <div className='flex flex-col'>
                                    <div className='h-[160px] w-[250px] bg-color-gray-4 rounded-lg'>
                                    </div>
                                    <div className='flex flex-col justify-center ml-4 '>
                                        <span className='my-2 title-2-regular'> Cras euismod </span>
                                        <div className='flex w-full max-w-[250px]'>
                                            <span className='border-b-2 !border-b-primary pb-3 subtitle'>
                                                Cras euismod porta fringilla. Vestibulum vitae
                                            </span>
                                        </div>
                                    </div>

                                </div>

                                <div className='flex flex-col'>
                                    <div className='h-[160px] w-[250px] bg-color-gray-4 rounded-lg'>
                                    </div>
                                    <div className='flex flex-col justify-center ml-4 '>
                                        <span className='my-2 title-2-regular'> Cras euismod </span>
                                        <div className='flex w-full max-w-[250px]'>
                                            <span className='border-b-2 !border-b-primary pb-3 subtitle'>
                                                Cras euismod porta fringilla. Vestibulum vitae
                                            </span>
                                        </div>
                                    </div>

                                </div>

                                <div className='flex flex-col'>
                                    <div className='h-[160px] w-[250px] bg-color-gray-4 rounded-lg'>
                                    </div>
                                    <div className='flex flex-col justify-center ml-4 '>
                                        <span className='my-2 title-2-regular'> Cras euismod </span>
                                        <div className='flex w-full max-w-[250px]'>
                                            <span className='border-b-2 !border-b-primary pb-3 subtitle'>
                                                Cras euismod porta fringilla. Vestibulum vitae
                                            </span>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>


                        {/* Footer */}
                        <Footer />

                        <div>

                        </div>
                    </div>

                </div>



            </div>
        </MainLayout>
    )
}

export default ResourcesPage;