import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LogoSVG from 'src/assets/svg/logo.svg';

function Footer() {



    const [scrollPosition, setScrollPosition] = useState(0);

    const handleClick = () => {
        window.location.href = "/";
    };


    return (
        <div className='flex justify-center my-20 flex-col px-5 md:px-0'>
            <div className='flex justify-center'>
                <div className='flex flex-row justify-between  w-full max-w-[1000px]'>
                    <div className='justify-center items-center hidden md:flex'>
                        <Link to="/"> <img src={LogoSVG} alt="logo" /></Link>
                    </div>

                    <div className='flex flex-col text-color-gray-2 px-2'>

                        <span className='text-inherit p2 md:subtitle pb-0 md:pb-3'> Pennytots</span>

                        <a href="https://www.facebook.com/100064270322197/posts/pfbid0HCzruDtruJoNSxRwcafgCM4BiDrGpuUYU84QY6Su8YRy558yBDPzcm89qTKf2uVfl" target="_blank" className='text-inherit p1-regular md:title-2-regular my-1' rel="noreferrer">Facebook</a>
                        <a href="/privacy-policy">
                            <span className='text-inherit p1-regular md:title-2-regular my-1'> Privacy Policy</span>
                        </a>

                        {/* <span className='text-inherit p1-regular md:title-2-regular my-1'> Admin Platform</span>
                        <span className='text-inherit p1-regular md:title-2-regular my-1'> Download</span>
                        <span className='text-inherit p1-regular md:title-2-regular my-1'> Messages</span> */}

                    </div>

                    <div className='flex flex-col text-color-gray-2 px-2'>
                        <span className='text-inherit p2 md:subtitle pb-0 md:pb-3'> Company</span>
                        <div onClick={handleClick} className="cursor-pointer">
                            <span className='text-inherit p1-regular md:title-2-regular my-1'> About us</span>
                        </div>
                        <a href="/contact-us">
                            <span className='text-inherit p1-regular md:title-2-regular my-1' >Contact </span>
                        </a>
                        <a href="/our-team">
                            <span className='text-inherit p1-regular md:title-2-regular my-1'> Our Team</span>
                        </a>
                       
                    </div>

                    <div className='flex flex-col text-color-gray-2 px-2'>
                    <a href= "#download-section">
                        <span className='text-inherit p2 md:subtitle pb-0 md:pb-3'> Download</span>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.pennytots.app" target="_blank" rel="noopener noreferrer">
                        <span className='text-inherit p1-regular md:title-2-regular my-1'> Android</span>
                        </a>
                        <span className='text-inherit p1-regular md:title-2-regular my-1'> iPhone</span>
                    </div>


                    <div className='flex flex-col text-color-gray-2 px-2'>
                        <span className='text-inherit p2 md:subtitle pb-0 md:pb-3'> Others</span>
                        <a href="/contact-us">
                            <span className='text-inherit p1-regular md:title-2-regular my-1'> Help Desk</span>
                        </a>
                        {/* <span className='text-inherit p1-regular md:title-2-regular my-1'> Privacy Policy</span>
                        <span className='text-inherit p1-regular md:title-2-regular my-1'> Terms of Use</span> */}
                    </div>

                </div>
            </div>

            <div className='justify-center items-center flex md:hidden mt-4'>
                <Link to="/">
                    <img src={LogoSVG} alt="logo" />
                </Link>
            </div>


            <div className='text-center mt-5'>
                <span className='text-color-gray-2 subtitle pb-3'> All right reserved</span>
            </div>
        </div>
    )
}

export default Footer
