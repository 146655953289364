import React, { useEffect } from 'react'
import MainLayout from 'src/layout/main'
import Team1 from 'src/assets/images/team-1.png';
import Team2 from 'src/assets/images/team-2.png';
import Team3 from 'src/assets/images/team-3.png';
import Team4 from 'src/assets/images/team-4.png';
import Team5 from 'src/assets/images/team-5.png';
import Team6 from 'src/assets/images/team-6.png';
import Team7 from 'src/assets/images/team-7.png';
import Team8 from 'src/assets/images/team-8.png';
import Team9 from 'src/assets/images/team-9.png';
import Footer from 'src/components/footer';

export default function OurTeamPage() {
     
    useEffect(() => {
        document.title = "Pennytots | Our Team";
        window.scrollTo(0, 0);
    }, []);


    const team = [
        {
            
            
            name: "Imoh Igho-Osagie",
            title: "Founder, CEO",
            img: Team1,
            link: 'https://www.linkedin.com/in/imuetinyan-igho-osagie/',
        },
        {
            
            title: "Director, Early Investor",
            name: "Igho-Osagie Ebuwa",
            img: Team8,
            link: 'https://www.linkedin.com/in/ebuwa/',
        },
        {
            
            title: "Director, Early Investor",
            name: "Igho-osagie Tosan",
            img: Team9,
            link: ' https://www.linkedin.com/in/tosanighoosagie/',
        },
        {
            title: "Fullstack Developer",
            name: "Ajetunmobi Isaac",
            img: Team2,
            link: 'https://www.linkedin.com/in/ajetunmobi-isaac/',

        },
        {
            title: "Backend Developer",
            name: "Abanigbe Olamilekan",
            img: Team3,
            link: 'https://www.linkedin.com/in/abanigbe-habeeb-olamilekan-b54310152/',
        },
        {
            title: "UI/UX Designer",
            name: "Orobosa Igho-Osagie",
            img: Team4,
            link: 'http://www.linkedin.com/in/orobosa-igho-osagie',
        },
        // {
        //     title: "Product Designer",
        //     name: "Adegoke David",
        //     img: Team5,
        //     link: 'https://www.linkedin.com/in/dboutcert/',
        // },
        {
            title: "Relationship Manager",
            name: "Chima Nnebue",
            img: Team6,
            link: 'https://www.linkedin.com/in/jahswey/',
        },
        {
            title: "Project Manager",
            name: "Jeremiah Nwaneri",
            img: Team7,
            link: 'https://www.linkedin.com/in/jeremiah-nwaneri/',
        }

    ]

    function onClick(){

    }

    return (
        <MainLayout>
            <div className='flex flex-col w-full'>
                <div className='flex items-center justify-center w-full mt-3'>
                    <span className='px-4 text-[20px] md:text-[30px] font-medium text-center'>Meet the Team</span>
                </div>


                <div className='p-10 grid gap-4 grid-cols-2 md:grid-cols-3'>

                    {team.map((item, index) => (
                        <a href={item.link} target='_blank' > 
                           <div className='cursor-pointer flex flex-col w-full justify-center items-center'>
                            <div className='flex justify-center'>
                                <img src={item.img} alt="" width={'80%'} />
                            </div>
                            <span className='mt-2 text-[16px] md:text-[18px] text-center font-semibold'>{item.title}</span>
                            <span className='text-[16px] md:text-[18px] text-center'>{item.name}</span>
                        </div>
                        </a>
                        
                     
                    ))}
                </div>


                {/* Footer */}
                <Footer />

            </div>


        </MainLayout>
    )
}
