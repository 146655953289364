import React from 'react';
import Select from 'react-select';

interface ISelectForm {
    options: {
        label: string;
        value: string;
    }[],
    customClass?: string;
    label?: string;
    onChange: (data: any) => void
}


const SelectForm = ({ options = [], customClass = "", label, onChange, ...rest }: ISelectForm) => {

    const customClassName = "my-custom-select";

    return (
        <div className='pb-[20px] w-full'>
            <div className='flex flex-col'>
                <label className='title pb-2'>
                    {label}
                </label>
                <Select
                    className={'my-4' + customClassName}
                    options={options}
                    onChange={(selected: any) => onChange(selected.value)}
                    {...rest}
                />
            </div>
        </div>


    );
};

export default SelectForm