import React, { useEffect, useRef, useState } from 'react'
import MenuSVG from 'src/assets/svg/menu.svg';
import { HashLink } from 'react-router-hash-link';

function MobileMenu({ menu = [] }: any) {

    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef<any>(null);

    function toggleDropdown() {
        setIsOpen(!isOpen);
    }

    function handleClickOutside(event: Event) {

        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    }

    useEffect(() => {
        if (isOpen) {
            window.addEventListener("click", handleClickOutside);
        }
        return () => {
            window.removeEventListener("click", handleClickOutside);
        };
    }, [isOpen]);





    return (
        <div className="flex flex-col" ref={menuRef}>
            <div>
                <img src={MenuSVG} alt="menu" onClick={toggleDropdown} />
            </div>

            {isOpen && (
                <div className="absolute z-50 right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <div
                        className="py-1"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                    >
                        {menu.map((item: any) => (
                            <>
                                {item.hashLink ? (
                                    <HashLink to={item.route}>
                                        <span className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                            role="menuitem"> {item.title}</span></HashLink>
                                ) : (
                                    <a
                                        href={item.route}
                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                        role="menuitem"
                                    >
                                        {item.title}
                                    </a>
                                )}
                            </>
                        ))}



                    </div>
                </div>
            )}
        </div>
    )
}

export default MobileMenu;