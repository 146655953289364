import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LandingPage from './pages';
import ResourcesPage from './pages/resources';
import ResetAppPasswordPage from './pages/guests/reset-app-password';
import ReportAnIssuePage from './pages/guests/report-issue';
import OurTeamPage from './pages/our-team';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import OurProcessPage from './pages/our-process';
import PrivacyAndPolicyPage from './pages/privacy-policy';

function App() {
  const recaptchaKey: any = process.env.REACT_APP_RECAPTCHA;



  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={recaptchaKey}
      // language="[optional_language]"
      // useRecaptchaNet="[optional_boolean_value]"
      // useEnterprise="[optional_boolean_value]"
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: 'head', // optional, default to "head", can be "head" or "body",
        nonce: undefined // optional, default undefined
      }}
      container={{ // optional to render inside custom element
        // element: "[required_id_or_htmlelement]",
        parameters: {
          badge: 'bottomright', // optional, default undefined
          theme: 'dark', // optional, default undefined
        }
      }}
    >
      <React.Fragment>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<LandingPage />} />
            <Route path='/privacy-policy' element={<PrivacyAndPolicyPage />} />
            <Route path='/download' element={<LandingPage />} />
            <Route path='/resources' element={<ResourcesPage />} />
            <Route path='/resources' element={<ResourcesPage />} />
            <Route path='/reset-password/:path' element={<ResetAppPasswordPage />} />
            <Route path='/contact-us' element={<ReportAnIssuePage />} />
            <Route path='/our-team' element={<OurTeamPage />} />
            <Route path='/our-process' element={<OurProcessPage />} />
          </Routes>
        </BrowserRouter>
      </React.Fragment>
    </GoogleReCaptchaProvider>
  );
}

export default App;
